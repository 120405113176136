export default (throttle = 500) => {
    const _timeout = ref(null);
    const throttleResets = ref(0);

    const throttledExecute = (callback) => {
        if (!callback) {
            throw createError({
                statusCode: '500',
                statusMessage: 'Fatal: No callback for @useThrottledApiCalls was passed',
            });
        }

        throttleResets.value += 1;

        clearTimeout(_timeout.value);
        _timeout.value = setTimeout(async () => {
            callback();
        }, throttle);
    };

    const rebaseResets = () => {
        throttleResets.value = 0;
    };

    return {
        throttleResets,
        throttledExecute,
        rebaseResets,
    };
};
